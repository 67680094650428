.loading-screen {
    background: linear-gradient(to bottom, #00C8FF, 20%, #006FFF);
}

/* Add this to your index.css */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.spinning {
    animation: spin 2s linear infinite;
}

/* Make the loading screen full-screen and disable scrolling */
.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    /* Optional: darken the background */
    z-index: 1000;
}

body.loading {
    overflow: hidden;
    /* Disable scrolling */
}