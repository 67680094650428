@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
}

.funnel h1 {
  font-family: "Podium", sans-serif;
  @apply text-[80px] md:text-[160px] text-center !tracking-normal uppercase  drop-shadow leading-[90%];
}
.funnel p.sub {
  @apply drop-shadow;
}

.funnel h2 {
  font-family: "Podium", sans-serif;
  @apply text-[60px] md:text-[140px] text-center leading-[90%] !tracking-normal uppercase drop-shadow;
}

.funnel h3 {
  font-family: "Podium", sans-serif;
  @apply text-[60px] md:text-[80px] lg:text-[100px] xl:text-[120px] text-center leading-[90%] !tracking-normal uppercase drop-shadow;
}

.funnel {
  font-family: "Inter", sans-serif !important;
  /* @apply overflow-hidden; */
}

.highlight {
  @apply text-[#1a1f23];
  background: linear-gradient(-180deg, #85c6ff 15%, #d5ebff 94%);
}

.funnel .letter {
  @apply md:text-3xl text-xl leading-tight md:leading-normal my-16 text-left space-y-7;
}

button:disabled {
  @apply transform-none;
}

.bonus {
  @apply bg-black p-1 px-5 w-fit uppercase text-3xl mx-auto mb-10 font-bold;
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: skew(-7deg);
  -ms-transform: skew(-7deg);
  transform: skew(-7deg);
}

.letter p {
  @apply leading-relaxed;
}

.form-question {
  @apply text-[45px] inter font-semibold;
  font-family: "Inter", sans-serif !important;
}

.form-input {
  @apply border-2 rounded-xl focus:border-main mt-10 mx-auto p-5 text-2xl md:w-3/12 w-full focus:ring-4 ring-blue-500/30 outline-none !font-normal;
}

.form-select {
  @apply border-2 rounded-2xl focus:border-main focus:ring-4 ring-blue-500/30 h-20 w-full flex items-center px-5 bg-white gap-5 cursor-pointer hover:border-main relative;
}

.form-select p {
  @apply text-2xl capitalize text-left leading-6;
}

.inter {
  font-family: "Inter", sans-serif !important;
}

.funnelCTA {
  /* Remove "md: !pb-0" */
  @apply w-full rounded-2xl text-[30px] sm:text-[40px] uppercase text-white md:px-10 md:p-4 p-2 leading-8 relative transform active:translate-y-0.5;
}

.funnelCTA .cta {
  font-family: "Podium", sans-serif !important;
  @apply text-3xl md:text-5xl tracking-wider;
}

.funnelCTA2 {
  /* Remove "md: !pb-0" */
  @apply w-full rounded-xl text-white p-2 relative transform active:translate-y-0.5 py-6;
}

.funnelCTA2 .cta {
  @apply text-xl leading-none font-normal drop-shadow-sm;
}

.slant {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: skew(-7deg);
  -ms-transform: skew(-7deg);
  transform: skew(-7deg);
}

.spots {
  @apply font-normal text-xl;
}

@keyframes bounceSlow {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-6px);
  }
}

.animate-bounce-slow {
  animation: bounceSlow 2s infinite;
}

/* slide */

.sliding-container {
  font-family: "FuturaLT", sans-serif;
  @apply text-4xl text-center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.sliding-wrapper {
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliding-row {
  display: flex;
  white-space: nowrap;
}

.text-slide {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  display: inline-block;
  margin: 0 5px;
  white-space: nowrap;
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: skew(-7deg);
  -ms-transform: skew(-7deg);
  transform: skew(-7deg);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.rotate {
  animation: rotate 1s ease-in-out infinite;
}

.slider-input {
  appearance: none;
  width: 100%;
  height: 20px;
  background: #d1d5db;
  /* Light gray background */
  border-radius: 50px;
  outline: none;
  transition: background 0.3s ease;
}

.slider-input::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 40px;
  border: 7px solid #0086ff;
  @apply shadow-sm bg-white;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}

/* ================== */
/* Hide scrollbar but allow scrolling */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
