@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.pfp {
  filter: grayscale(100%);
}

.pfp:hover {
  filter: grayscale(0%);
}

@font-face {
  font-family: "FuturaLT";
  src: url(./fonts/FuturaLT.ttf) format("truetype");
}

@font-face {
  font-family: "Podium";
  src: url(./fonts/PODIUMSharp-211.ttf) format("truetype");
}

/* ==[ base ]== */

body {
  font-family: "Montserrat", sans-serif;
  background-color: #f4f4f8;
  /* f6fcf6 */
}

.swipe-icon {
  animation: swipe 2s infinite ease-in-out;
}

@keyframes swipe {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  50% {
    transform: translateX(20px);
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
    opacity: 0;
  }
}


.loader {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top: 4px solid #fff;
  /* Change this color to match your theme */
  border-radius: 50%;
  width: 25px;
  /* Adjust size as needed */
  height: 25px;
  /* Adjust size as needed */
  animation: spin 1s linear infinite;
}

img {
  pointer-events: none;
  user-drag: none;
  -webkit-user-drag: none;
}

img {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

h1 {
  @apply text-5xl lg:text-6xl;
}

h1.headline {
  @apply text-5xl lg:!text-8xl !tracking-normal;
  font-family: "Podium", sans-serif;
}

h2 {
  @apply text-4xl lg:text-5xl;
}

/* ==[ additional ]== */

button.cta {
  @apply px-6 h-[50px] font-semibold rounded-full bg-main text-white;
}

button.cta-3 {
  @apply items-center justify-center w-full h-12 mt-6 capitalize !rounded-2xl border-2 border-main bg-transparent font-semibold text-main hover:text-white hover:bg-main;
}

button.cta-2 {
  @apply px-10 h-[75px] font-semibold rounded-full bg-main text-white;
}

::selection {
  background-color: #0086ff;
  color: white;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.back-to-top {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.back-to-top-button {
  @apply bg-black/50 border h-10 backdrop-blur-sm text-white rounded-full w-10 flex items-center justify-center;
}

.website-card {
  background: rgb(43, 154, 255);
  background: linear-gradient(
    180deg,
    rgba(43, 154, 255, 1) 0%,
    rgba(86, 8, 255, 1) 54%
  );
}

.ai-card {
  background: rgb(9, 87, 9);
  background: linear-gradient(180deg, rgba(9, 87, 9, 1) 0%, rgb(6, 15, 6) 73%);
}

.ai-hero {
  background: rgb(81, 201, 81);
  background: linear-gradient(
    360deg,
    rgba(81, 201, 81, 0.1) 0%,
    rgba(0, 0, 0, 0.75) 80%
  );
}

.website-hero {
  background: rgb(0, 134, 255);
  background: linear-gradient(
    360deg,
    rgba(0, 26, 49, 0.2) 0%,
    rgba(0, 0, 0, 0.65) 80%
  );
}

.ai-card,
.website-card {
  @apply transform duration-150 hover:-translate-y-2 cursor-pointer;
}

.tap {
  animation: tilt 1.5s infinite;
}

@keyframes tilt {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
